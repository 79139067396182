import { useEffect } from "react";
import { patchData } from "../api/apiService";
import { fetchUserPermission } from "../components/SecurityComponent/SecurityComponent";
import { useNavigate } from "react-router-dom";

const useSecurityErrorCheck = (instance) => {
  const navigate = useNavigate();
  useEffect(() => {
    const reportUserError = async () => {
      const userId = localStorage.getItem("user_id");
      if (!userId) {
        return;
      }

      try {
        const userPermissions = await fetchUserPermission();
        const invalidUrl = window.location.href; // send the current url on errorAccesss api 
        const response = await patchData(`/users/${userId}/errorAccess`, { invalidUrl });
        // if user error count is max -1 , then navigate to final warning
        let securityErrorCount = userPermissions.data.securityErrorCount - 1;
        let userErrorCount = userPermissions.data.user.userErrorCount + 1;
        if (userErrorCount === securityErrorCount) {
          navigate("/final-attempt");
        }
        if (response.data.statusCode === 403) {
          localStorage.clear();
          instance.logoutRedirect({ postLogoutRedirectUri: "/" });
        }
      } catch (error) {
        localStorage.clear();
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
        // console.error("Error reporting user error:", error);
      }
    };

    const userId = localStorage.getItem("user_id");
    if (userId) {
      reportUserError();
    }
  }, [instance]);
};

export default useSecurityErrorCheck;
