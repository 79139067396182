import React from "react";

const FinalAttemptPage = () => {

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Final URL Error Warning</h1>
      <p style={styles.text}>
        This is the last chance for the URL errors. If this error is repeated
        again your CareHub account will be locked.
      </p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    marginTop: "100px",
  },
  heading: {
    fontSize: "36px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  text: {
    fontSize: "18px",
    marginBottom: "10px",
  },
};

export default FinalAttemptPage;
