import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Pagination,
  Breadcrumb,
} from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchData, postData, deleteData } from "../../api/apiService";
import { FaTrash, FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { usePermissions } from "carehub_auth_permissions";
import Loader from "../../ui/Layout/Loader";

const PracticeExclusion = () => {
  const [partners, setPartners] = useState([]);
  const [practiceExclusions, setPracticeExclusions] = useState([]);
  const [selectedPartnerFilter, setSelectedPartnerFilter] = useState([]);
  const [selectedPracticeFilter, setSelectedPracticeFilter] = useState([]);
  const [practiceOptions, setPracticeOptions] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deletePartner, setDeletePartner] = useState("");
  const [deletePractice, setDeletePractice] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState("partnerName");
  const [sortOrder, setSortOrder] = useState("ASC");
  const { permissions, permissionsLoaded } = usePermissions();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const loadData = async () => {
    try {
      const partnersResponse = await fetchData(
        "/pvps/jvpPartnerWithPractices?showExcluded=true"
      );
      const partnerOptions = partnersResponse.jvpPractices.map((jvp) => ({
        value: jvp.jvpName,
        label: jvp.jvpName,
        practices: jvp.practiceNames.map((practice) => ({
          value: practice,
          label: practice,
        })),
      }));
      setPartners(partnerOptions);

      // Build query parameters
      const queryParams = new URLSearchParams({
        page: currentPage,
        sortBy,
        sortOrder,
      });

      // Update to use separate partnerName and practiceName params
      if (selectedPartnerFilter.length > 0) {
        queryParams.append(
          "partnerName",
          selectedPartnerFilter.map((p) => p.value).join(",")
        );
      }

      if (selectedPracticeFilter.length > 0) {
        queryParams.append(
          "practiceName",
          selectedPracticeFilter.map((p) => p.value).join(",")
        );
      }

      // Fetch exclusions with new query parameters
      const exclusionsResponse = await fetchData(
        `/practice-exclusion?${queryParams.toString()}`
      );
      setPracticeExclusions(exclusionsResponse.practiceExclusions);
      setTotalPages(
        Math.max(
          1,
          exclusionsResponse.totalPages ||
            Math.ceil(exclusionsResponse.practiceExclusionsCount / itemsPerPage)
        )
      );
    } catch (error) {
      console.error("Error loading data:", error);

      if (error.statusCode && error.statusCode === 403) {
        navigate("/403", { replace: true });
      }

      setLoading(false);
    }
};


  useEffect(() => {
    loadData();
  }, [
    currentPage,
    sortBy,
    sortOrder,
    selectedPartnerFilter,
    selectedPracticeFilter,
  ]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
    } else {
      setSortBy(column);
      setSortOrder("ASC");
    }
  };

  const getSortIcon = (column) => {
    if (sortBy === column) {
      return sortOrder === "ASC" ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort />;
  };

  const handlePartnerFilterChange = (selectedOptions) => {
    setSelectedPartnerFilter(selectedOptions || []);
    const selectedJvpValues = selectedOptions
      ? selectedOptions.map((jvp) => jvp.value)
      : [];

    const newPracticeOptions = partners
      .filter((jvp) => selectedJvpValues.includes(jvp.value))
      .flatMap((jvp) => jvp.practices);

    setPracticeOptions(newPracticeOptions);
    setSelectedPracticeFilter([]);
  };

  const handlePracticeFilterChange = (selectedOptions) => {
    setSelectedPracticeFilter(selectedOptions || []);
  };

  const handleAddExclusion = () => {
    setSelectedPartner(null);
    setSelectedPractice(null);
    setShowModal(true);
  };

  const handlePartnerChange = (selectedOption) => {
    setSelectedPartner(selectedOption);
    setSelectedPractice(null);
  };

  const getFilteredPractices = () => {
    if (!selectedPartner) return [];
    const partnerPractices =
      partners.find((p) => p.value === selectedPartner.value)?.practices || [];
    const excludedPractices = practiceExclusions
      .filter((exclusion) => exclusion.partnerName === selectedPartner.value)
      .map((exclusion) => exclusion.practiceName);
    return partnerPractices.filter(
      (practice) => !excludedPractices.includes(practice.value)
    );
  };

  const handleSubmitExclusion = async () => {
    if (!selectedPartner || !selectedPractice) {
      toast.error("Please select both Partner and Practice.");
      return;
    }

    try {
      await postData("/practice-exclusion", {
        partnerName: selectedPartner.value,
        practiceName: selectedPractice.value,
      });
      toast.success(
        `Exclusion Added: ${selectedPartner.label} - ${selectedPractice.label}`
      );
      setShowModal(false);
      await loadData();
    } catch (error) {
      toast.error("Failed to add exclusion.");
    }
  };

  const handleDelete = (id, partner, practice) => {
    setDeleteId(id);
    setDeletePartner(partner);
    setDeletePractice(practice);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteData(`/practice-exclusion/${deleteId}`);
      toast.success("Exclusion successfully deleted.");
      setShowDeleteModal(false);
      await loadData();
    } catch (error) {
      toast.error("Failed to delete exclusion.");
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      {!permissionsLoaded ? (
        <Loader />
      ) : permissions?.includes("administration_practice_exclusion") ? (
        <div>
          <Container className="mt-2 px-0">
            <ToastContainer />
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link className="text-dark link" to="/administration">
                  Administration
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                Excluded Partners & Practices
              </Breadcrumb.Item>
            </Breadcrumb>

            <div className="d-flex justify-content-between align-items-center mb-3">
              <h3>Excluded Partners & Practices</h3>
              {permissionsLoaded &&
                permissions?.includes("practice_exclusion_add") && (
                  <Button className="primary-btn" onClick={handleAddExclusion}>
                    Add Exclusion
                  </Button>
                )}
            </div>
          </Container>
          <Container className="border rounded px-4 pt-3 mt-2">
            <Row className="mb-3 align-items-center">
              <Col md={3}>
                <Select
                  options={partners}
                  onChange={handlePartnerFilterChange}
                  isMulti
                  placeholder="Select Partner"
                />
              </Col>
              <Col md={3}>
                <Select
                  options={practiceOptions}
                  onChange={handlePracticeFilterChange}
                  isMulti
                  placeholder="Select Practice Name"
                  isDisabled={selectedPartnerFilter.length === 0}
                />
              </Col>
            </Row>
            <hr></hr>

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th
                    onClick={() => handleSort("partnerName")}
                    style={{ cursor: "pointer" }}
                  >
                    Partner Name {getSortIcon("partnerName")}
                  </th>
                  <th
                    onClick={() => handleSort("practiceName")}
                    style={{ cursor: "pointer" }}
                  >
                    Practice Name {getSortIcon("practiceName")}
                  </th>
                  <th
                    onClick={() => handleSort("dateExcluded")}
                    style={{ cursor: "pointer" }}
                  >
                    Date Excluded {getSortIcon("dateExcluded")}
                  </th>
                  <th>Excluded By</th>
                  {permissionsLoaded &&
                    permissions?.includes("practice_exclusion_delete") && (
                      <th>Action</th>
                    )}
                </tr>
              </thead>
              <tbody>
                {practiceExclusions.length > 0 ? (
                  practiceExclusions.map((row, index) => (
                    <tr key={row.id}>
                      <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                      <td>{row.partnerName}</td>
                      <td>{row.practiceName}</td>
                      <td>{new Date(row.dateExcluded).toLocaleString()}</td>
                      <td>{row.excludedBy}</td>
                      {permissionsLoaded &&
                        permissions?.includes("practice_exclusion_delete") && (
                          <td>
                            <Button
                              variant="danger"
                              onClick={() =>
                                handleDelete(
                                  row.id,
                                  row.partnerName,
                                  row.practiceName
                                )
                              }
                            >
                              <FaTrash />
                            </Button>
                          </td>
                        )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No exclusions found.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            <div className="d-flex justify-content-end">
              <Pagination>
                <Pagination.First
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: totalPages }, (_, i) => (
                  <Pagination.Item
                    key={i + 1}
                    active={i + 1 === currentPage}
                    onClick={() => handlePageChange(i + 1)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                <Pagination.Last
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            </div>

            <Modal
              show={showDeleteModal}
              onHide={() => setShowDeleteModal(false)}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Confirm Deletion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to remove this exclusion?{" "}
                <strong>
                  {deletePartner} - {deletePractice}
                </strong>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </Button>
                <Button variant="danger" onClick={confirmDelete}>
                  Yes, Delete
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
              <Modal.Header closeButton>
                <Modal.Title>Add Exclusion</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="partner">
                        <Form.Label>Select Partner</Form.Label>
                        <Select
                          options={partners}
                          onChange={handlePartnerChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="practices">
                        <Form.Label>Select Practice</Form.Label>
                        <Select
                          options={getFilteredPractices()}
                          onChange={setSelectedPractice}
                          isDisabled={!selectedPartner}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                  Close
                </Button>
                <Button
                  className="primary-btn"
                  onClick={handleSubmitExclusion}
                  disabled={!selectedPartner || !selectedPractice}
                >
                  Exclude Practice
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </div>
      ) : (
        <Navigate to="/403" replace />
      )}
    </div>
  );
};

export default PracticeExclusion;
