import { Outlet } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Header from "./Header";
import Footer from "./Footer";

const Home = () => {
  const azureToken = localStorage.getItem("accessToken");
  if (azureToken) {
    const decodedValue = jwtDecode(azureToken ?? null);
    if (decodedValue && Object.keys(decodedValue).length !== 0) {
      localStorage.setItem("user_email", decodedValue?.["email"]);
    }
  }

  return (
    <div>
      <Header />
      <div className="app-container">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
