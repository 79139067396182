import { FaEye } from "react-icons/fa";
import { Container, Row, Col, Card } from "react-bootstrap";
import React from "react";
import { FaUsers } from "react-icons/fa6";
import { CiSettings } from "react-icons/ci";
import { Link, Navigate } from "react-router-dom";
import Loader from "../../ui/Layout/Loader";
import { usePermissions } from "carehub_auth_permissions";
import { BiHide } from "react-icons/bi";

const Administration = () => {
  const { permissions, permissionsLoaded } = usePermissions();

  if (!permissionsLoaded) {
    return <Loader />;
  }

  return (
    <>
      {permissions?.includes("home_admin") ? (
        <Container className="border border-light rounded px-5 mt-2">
          <h3>Welcome to Honest CareHub Administration!</h3>
          <Row className="justify-content-center mt-4">
            {permissions?.includes("administration_app_configuration") && (
              <Col md={4}>
                <Card>
                  <div className="card-body insights-card">
                    <div className="card-text text-center rounded pt-3">
                      <CiSettings className="fs-4"></CiSettings>
                      <h5 className="fw-bold mt-2">
                        Application Configuration
                      </h5>
                    </div>
                    <div>
                      <p className="fs-6 p-3 text-center">
                        Lorem ipsum dolor sit amet, consectetur  elcentriccio adipisicing
                        elit.
                      </p>
                    </div>
                  </div>
                  <div className="card-footer primary-bg text-center text-white">
                    <Link
                      to="/application_configuration"
                      className="nav-link text-white"
                    >
                      <FaEye></FaEye> View
                    </Link>
                  </div>
                </Card>
              </Col>
            )}
            {permissions?.includes("administration_user_management") && (
              <Col md={4}>
                <Card>
                  <div className="card-body insights-card">
                    <div className="card-text text-center rounded pt-3">
                      <FaUsers className="fs-4"></FaUsers>
                      <h5 className="fw-bold mt-2">User Management</h5>
                    </div>
                    <div>
                      <p className="fs-6 p-3 text-center">
                        Lorem ipsum dolor sit amet, consectetur  elcentriccio adipisicing
                        elit.
                      </p>
                    </div>
                  </div>
                  <div className="card-footer primary-bg text-center text-white">
                    <Link to="/user_management" className="nav-link text-white">
                      <FaEye></FaEye> View
                    </Link>
                  </div>
                </Card>
              </Col>
            )}
            {permissions?.includes("administration_practice_exclusion") && (
              <Col md={4}>
                <Card>
                  <div className="card-body insights-card">
                    <div className="card-text text-center rounded pt-3">
                      <BiHide className="fs-4"></BiHide>
                      <h5 className="fw-bold mt-2">Practice Exclusion</h5>
                    </div>
                    <div>
                      <p className="fs-6 p-3 text-center">
                        Declare individual practices to be exclued from the
                        Outbound Patient Listing
                      </p>
                    </div>
                  </div>
                  <div className="card-footer primary-bg text-center text-white">
                    <Link to="/exclude_practices" className="nav-link text-white">
                      <FaEye></FaEye> View
                    </Link>
                  </div>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      ) : (
        <Navigate to="/401" replace />
      )}
    </>
  );
};

export default Administration;
