import React from "react";
import { useMsal } from "@azure/msal-react";
import useSecurityErrorCheck from "../../../hooks/useSecurityErrorCheck";

const LockedAccountPage = () => {
  const { instance } = useMsal();
  useSecurityErrorCheck(instance);

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Account Locked</h1>
      <p style={styles.text}>
        Your account has been locked as you have exceeded the error count.<br></br>
        Please contact the CareHub system administrator for assistance.
      </p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    marginTop: "100px",
  },
  heading: {
    fontSize: "36px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  text: {
    fontSize: "18px",
    marginBottom: "10px",
  },
};

export default LockedAccountPage;
