import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { Insights, InsightsRoutes } from "carehub_insights";
import { PVPHomeComponent, PVPRoutes } from "carehub_pvp";
import Layout from "./ui/Layout/Layout";
import HomePage from "./ui/Pages/Public/Homepage";
import AdministrationPage from "./ui/Pages/Private/AdministrationPage";
import ApplicationConfigurationPage from "./ui/Pages/Private/ApplicationConfigurationPage";
import UserManagementPage from "./ui/Pages/Private/UserManagementPage";
import RequestDetailsPage from "./ui/Pages/Private/RequestDetailsPage";
import RoleManagementPage from "./ui/Pages/Private/RoleManagementPage";
import Error401Page from "./ui/Pages/ErrorPages/Error401Page";
import { checkTokenExpiration } from "./authMiddleware";
import Error404Page from "./ui/Pages/ErrorPages/Error404Page";
import "react-toastify/dist/ReactToastify.css";
import HealthPage from "./ui/Pages/Public/HealthPage";
import Error403Page from "./ui/Pages/ErrorPages/Error403Page";
import { msalInstance } from "./index";
import { acquireTokenSilent, acquireTokenInteractive } from "../src/authUtil";
import AgreementPage from "./ui/Pages/Private/AgreementPage";
import EulaPage from "./ui/Pages/Public/EulaPage";
import TermsOfUsagePage from "./ui/Pages/Public/TermsOfUsagePage";
import ContactPage from "./ui/Pages/Public/ContactPage";
import LockedAccountPage from "./ui/Pages/ErrorPages/LockedAccountPage";
import FinalAttemptPage from "./ui/Pages/ErrorPages/FinalAttemptPage";
import PracticeExclusionPage from "./ui/Pages/Private/PracticeExclusion";

export default function App() {
  const [tokenExpired, setTokenExpired] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const { instance } = useMsal();
  const [isInteractionInProgress, setIsInteractionInProgress] = useState(false);

  useEffect(() => {
    try {
      if (window.self !== window.top) {        
        document.body.innerHTML = "<h1>Embedding not allowed</h1>";
      }
    } catch (error) {
      console.error("Error detecting iframe embedding:", error);
    }
  }, []);

  useEffect(() => {
    const { expired } = checkTokenExpiration();
    if (expired) {
      localStorage.clear();
      setTokenExpired(true);
    }
  }, []);

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await acquireTokenSilent();
        setAccessToken(token);
      } catch (error) {
        console.error(
          "Failed to acquire token silently, trying interactive method",
          error
        );
        if (!isInteractionInProgress) {
          setIsInteractionInProgress(true);
          acquireTokenInteractive();
        }
      }
    };

    getToken();
  }, [isInteractionInProgress]);

  useEffect(() => {
    if (!instance) {
      console.error("MSAL instance is not initialized.");
      return;
    }
    const { accounts } = instance.getAllAccounts() || {};
  }, [instance]);

  if (tokenExpired) {
    return (
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="agreement" element={<AgreementPage />} />
          <Route path="enduseragreement" element={<EulaPage />} />
          <Route path="termsofuse" element={<TermsOfUsagePage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/insights/*" element={<InsightsRoutes />} />
          <Route path="*" element={<Error404Page />} />
        </Route>
        <Route path="health" element={<HealthPage />} />
      </Routes>
    );
  } else {
    return (
      <MsalProvider instance={msalInstance}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="agreement" element={<AgreementPage />} />
            <Route path="enduseragreement" element={<EulaPage />} />
            <Route path="termsofuse" element={<TermsOfUsagePage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="/insights" element={<Insights />} />
            <Route path="/insights/*" element={<InsightsRoutes />} />
            <Route path="administration" element={<AdministrationPage />} />
            <Route path="pvp" element={<PVPHomeComponent />} />
            <Route path="/pvp/*" element={<PVPRoutes />} />
            <Route
              path="application_configuration"
              element={<ApplicationConfigurationPage />}
            />
            <Route path="user_management" element={<UserManagementPage />} />
            <Route path="request_details" element={<RequestDetailsPage />} />
            <Route path="role_management" element={<RoleManagementPage />} />
            <Route path="exclude_practices" element={<PracticeExclusionPage />} />
            <Route path="401" element={<Error401Page />} />
            <Route path="403" element={<Error403Page />} />
            <Route path="account-locked" element={<LockedAccountPage />} />
            <Route path="final-attempt" element={<FinalAttemptPage />} />
            <Route path="*" element={<Error404Page />} />
          </Route>
          <Route path="health" element={<HealthPage />} />
        </Routes>
      </MsalProvider>
    );
  }
}
