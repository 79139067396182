import { Container } from "react-bootstrap";
import React from "react";
/* eslint-disable */
const TermsOfUsage = () => {
  return (
    <Container className="border border-light rounded px-5 mt-2">
      <>
        <p>CareHub Terms of Use</p>
        <p>Last Modified: June 1, 2024</p>
        <p>
          <strong>
            <u>Acceptance of the Terms of Use</u>
          </strong>
        </p>
        <p>
          These terms of use are entered into by and between you and{" "}
          <strong>Honest Medical Group LLC</strong> ("<strong>Company</strong>,"
          "<strong>we</strong>," or "<strong>us</strong>"). The following terms
          and conditions, together with the End User License Agreement (“
          <strong>EULA</strong>”) and Privacy Policy, are hereby incorporated by
          this reference, (collectively, "<strong>Terms of Use</strong>"),
          govern your access to and use of the CareHub application (“
          <strong>CareHub</strong>” or “<strong>Application</strong>”),
          including any content, functionality, and services offered on or
          through www.honesthealth.com &nbsp;(the "<strong>Website</strong>").
        </p>
        <p>
          Please read the Terms of Use carefully before you start to use
          CareHub.{" "}
          <strong>
            By using CareHub or by clicking to accept or agree to the Terms of
            Use when this option is made available to you, you accept and agree
            to be bound and abide by these Terms of Use and EULA.
          </strong>{" "}
          If you do not want to agree to these Terms of Use and EULA, you must
          not access or use CareHub.
        </p>
        <p>
          CareHub is offered and available to users who are eighteen (18) years
          of age, residing in the United States or any of its territories or
          possessions, and registered and approved by Company (“Authorized
          User”).&nbsp; By using CareHub, you represent and warrant that you are
          of legal age to form a binding contract with the Company and meet all
          of the foregoing eligibility requirements. If you do not meet all of
          these requirements, you must not access or use CareHub.
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Changes to the Terms of Use</u>
          </strong>
        </p>
        <p>
          We may revise and update these Terms of Use from time to time in our
          sole discretion. All changes are effective immediately when we post
          them and apply to all access to and use of CareHub.&nbsp; However, any
          changes to the dispute resolution provisions set out in{" "}
          <a href="#a699054">Governing Law and Jurisdiction</a> will not apply
          to any disputes for which the parties have actual notice on or before
          the date the change is posted on CareHub.&nbsp; &nbsp;
        </p>
        <p>
          Your continued use of CareHub following the posting of revised Terms
          of Use means that you accept and agree to the changes. You are
          expected to check this page from time to time as you access CareHub so
          you are aware of any changes, as they are binding on you.
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Accessing CareHub and Account Security</u>
          </strong>
        </p>
        <p>
          We reserve the right to withdraw or amend CareHub, and any service or
          material we provide <em>via</em> the Application, in our sole
          discretion without notice. We will not be liable if for any reason all
          or any part of CareHub is unavailable at any time or for any period.
          From time to time, we may restrict user access, including registered
          user access, to some parts of CareHub or the entire Application.
        </p>
        <p>You are responsible for both:</p>
        <ul>
          <li>
            Making all arrangements necessary for you to have access to CareHub.
          </li>
          <li>
            Ensuring that all persons who access CareHub through your internet
            connection are aware of these Terms of Use and comply with them.
          </li>
        </ul>
        <p>
          To access CareHub or some of the resources it offers, you may be asked
          to provide certain registration details or other information. It is a
          condition of your use of the Application, and all the information you
          provide must be correct, current, and complete. You agree that all
          information you provide to register with CareHub or otherwise,
          including, but not limited to, through the use of any interactive
          features on CareHub, is governed by the EULA and these Terms of Use,
          and you consent to all actions we take with respect to your
          information.
        </p>
        <p>
          If you choose, or are provided with, a username, password, or any
          other piece of information as part of our security procedures, you
          must treat such information as confidential, and you must not disclose
          it to any other person or entity. You also acknowledge that your
          account is personal to you and agree not to provide any other person
          with access to CareHub or portions of it using your username,
          password, or other security information. You agree to notify us
          immediately of any unauthorized access to or use of your username or
          password or any other breach of security. You also agree to ensure
          that you exit from your account at the end of each session. You should
          use particular caution when accessing your account from a public or
          shared computer so that others are not able to view or record your
          password or other personal information.
        </p>
        <p>
          We have the right to disable any username, password, or other
          identifier, whether chosen by you or provided by us, at any time in
          our sole discretion for any or no reason, including if, in our
          opinion, you have violated any provision of these Terms of Use.
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Intellectual Property Rights</u>
          </strong>
        </p>
        <p>
          CareHub and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof) are owned by the Company, its licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </p>
        <p>
          These Terms of Use permit you to use the Application for your
          personal, non-commercial use only. You must not reproduce, distribute,
          modify, create derivative works of, publicly display, publicly
          perform, republish, download, store, or transmit any of the material
          on CareHub, except as follows:
        </p>
        <ul>
          <li>
            Your computer may temporarily store copies of such materials in RAM
            incidental to your accessing and viewing those materials.
          </li>
          <li>
            You may store files that are automatically cached by your Web
            browser for display enhancement purposes.
          </li>
          <li>
            If we provide desktop, mobile, or other applications for download,
            you may download a single copy to your computer or mobile device
            solely for your own personal, non-commercial use, provided you agree
            to be bound by our end user license agreement for such applications.
          </li>
        </ul>
        <p>You must not:</p>
        <ul>
          <li>Modify copies of any materials from this Application.</li>
          <li>
            Use any illustrations, photographs, video or audio sequences, or any
            graphics separately from the accompanying text.
          </li>
          <li>
            Delete or alter any copyright, trademark, or other proprietary
            rights notices from copies of materials from this Application.
          </li>
        </ul>
        <p>
          You must not access or use CareHub or any part of the Application or
          any services or materials available through the Application for any
          unauthorized purpose. Company is not obligated but reserves the right
          to audit your use of the Application to ensure your compliance with
          the Terms of Use and EULA.
        </p>
        <p>
          If you print, copy, modify, download, or otherwise use or provide any
          other person with access to any part of CareHub in breach of the Terms
          of Use, your right to use CareHub will cease immediately and you must,
          at our option, return or destroy any copies of the materials you have
          made. No right, title, or interest in or to the Application or any
          content on the Application is transferred to you, and all rights not
          expressly granted are reserved by the Company. Any use of CareHub not
          expressly permitted by these Terms of Use is a breach of these Terms
          of Use and may violate copyright, trademark, and other laws.
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Trademarks</u>
          </strong>
        </p>
        <p>
          The Company name, these Terms of Use, Company trademarks, Company
          logos, and all related names, logos, product and service names,
          designs, and slogans are trademarks of the Company or its affiliates
          or licensors. You must not use such marks without the prior written
          permission of the Company. All other names, logos, product and service
          names, designs, and slogans on CareHub are the trademarks of their
          respective owners.
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Prohibited Uses</u>
          </strong>
        </p>
        <p>
          You may use CareHub only for lawful purposes and in accordance with
          these Terms of Use. You agree not to use CareHub:
        </p>
        <ul>
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation (including, without limitation, any
            laws regarding the export of data or software to and from the U.S.
            or other countries).
          </li>
          <li>
            For the purpose of exploiting, harming, or attempting to exploit or
            harm minors in any way by exposing them to inappropriate content,
            asking for personally identifiable information, or otherwise.
          </li>
          <li>
            To send, knowingly receive, upload, download, use, or re-use any
            material that does not comply with the Content Standards set out in
            these Terms of Use.
          </li>
          <li>
            To transmit, or procure the sending of, any advertising or
            promotional material, including any "junk mail," "chain letter,"
            "spam," or any other similar solicitation.
          </li>
          <li>
            To impersonate or attempt to impersonate the Company, a Company
            employee, another user, or any other person or entity (including,
            without limitation, by using email addresses or screen names
            associated with any of the foregoing.
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the Application, or which, as determined by us,
            may harm the Company or users of the Application, or expose them to
            liability.
          </li>
        </ul>
        <p>Additionally, you agree not to:</p>
        <ul>
          <li>
            Use the Application in any manner that could disable, overburden,
            damage, or impair the site or interfere with any other party's use
            of the Application, including their ability to engage in real time
            activities through CareHub.
          </li>
          <li>
            Use any robot, spider, or other automatic device, process, or means
            to access CareHub for any purpose, including monitoring or copying
            any of the material on the Application.
          </li>
          <li>
            Use any manual process to monitor or copy any of the material on
            CareHub, or for any other purpose not expressly authorized in these
            Terms of Use, without our prior written consent.
          </li>
          <li>
            Use any device, software, or routine that interferes with the proper
            working of the Application.
          </li>
          <li>
            Introduce any viruses, Trojan horses, worms, logic bombs, or other
            material that is malicious or technologically harmful.
          </li>
          <li>
            Attempt to gain unauthorized access to, interfere with, damage, or
            disrupt any parts of the Application, the server on which the
            Application is stored, or any server, computer, or database
            connected to CareHub.
          </li>
          <li>
            Attack the Application via a denial-of-service attack or a
            distributed denial-of-service attack.
          </li>
          <li>
            Otherwise attempt to interfere with the proper working of CareHub
            and/or use it in a manner that is inconsistent in any respect with
            Company’s intended, authorized purpose.
          </li>
        </ul>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>User Contributions</u>
          </strong>
        </p>
        <p>
          Subject to Company’s discretion for purposes of development, the
          Application may contain profiles, forums, bulletin boards, other
          interactive features (collectively, "
          <strong>Interactive Services</strong>") that allow users to post,
          submit, publish, display, or transmit to other users or other persons
          (hereinafter, "<strong>post</strong>") content or materials
          (collectively, "<strong>User Contributions</strong>") on or through
          the Application.
        </p>
        <p>
          All User Contributions must comply with the Content Standards set out
          in these Terms of Use.
        </p>
        <p>
          Any User Contribution you post to the Application will be considered
          non-confidential and non-proprietary. By providing any User
          Contribution on the Application, you grant us and our affiliates and
          service providers, and each of their and our respective licensees,
          successors, and assigns the right to use, reproduce, modify, perform,
          display, distribute, and otherwise disclose to third parties any such
          material for any purpose/according to your account settings.
        </p>
        <p>You represent and warrant that:</p>
        <ul>
          <li>
            You own or control all rights in and to the User Contributions and
            have the right to grant the license granted above to us and our
            affiliates and service providers, and each of their and our
            respective licensees, successors, and assigns.
          </li>
          <li>
            All of your User Contributions do and will comply with these Terms
            of Use.
          </li>
        </ul>
        <p>
          You understand and acknowledge that you are responsible for any User
          Contributions you submit or contribute, and you, not the Company, have
          full responsibility for such content, including its legality,
          reliability, accuracy, and appropriateness.
        </p>
        <p>
          We are not responsible or liable to any third party for the content or
          accuracy of any User Contributions posted by you or any other user of
          the Application.
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Monitoring and Enforcement; Termination</u>
          </strong>
        </p>
        <p>We have the right to:</p>
        <ul>
          <li>
            Remove or refuse to post any User Contributions for any or no reason
            in our sole discretion.
          </li>
          <li>
            Take any action with respect to any User Contribution that we deem
            necessary or appropriate in our sole discretion, including if we
            believe that such User Contribution violates the Terms of Use,
            including the Content Standards, infringes any intellectual property
            right or other right of any person or entity, threatens the personal
            safety of users of the Application or the public, or could create
            liability for the Company.
          </li>
          <li>
            Disclose your identity or other information about you to any third
            party who claims that material posted by you violates their rights,
            including their intellectual property rights or their right to
            privacy.
          </li>
          <li>
            Take appropriate legal action, including without limitation,
            referral to law enforcement, for any illegal or unauthorized use of
            the Application.
          </li>
          <li>
            Terminate or suspend your access to all or part of the Application
            for any or no reason, including without limitation, any violation of
            these Terms of Use.
          </li>
        </ul>
        <p>
          Without limiting the foregoing, we have the right to cooperate fully
          with any law enforcement authorities or court order requesting or
          directing us to disclose the identity or other information of anyone
          posting any materials on or through the Application. YOU WAIVE AND
          HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE
          PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY THE
          COMPANY/ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE
          OF, INVESTIGATIONS BY EITHER THE COMPANY/SUCH PARTIES OR LAW
          ENFORCEMENT AUTHORITIES.
        </p>
        <p>
          While we review all material before it is posted on the Application,
          we cannot ensure prompt removal of objectionable material after it has
          been posted.&nbsp; Accordingly, we assume no liability for any action
          or inaction regarding transmissions, communications, or content
          provided by any user or third party. We have no liability or
          responsibility to anyone for performance or nonperformance of the
          activities described in this section.
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Content Standards</u>
          </strong>
        </p>
        <p>
          These content standards apply to any and all User Contributions and
          use of Interactive Services, as applicable. User Contributions must in
          their entirety comply with all applicable federal, state, local, and
          international laws and regulations. Without limiting the foregoing,
          User Contributions must not:
        </p>
        <ul>
          <li>
            Contain any material that is defamatory, obscene, indecent, abusive,
            offensive, harassing, violent, hateful, inflammatory, or otherwise
            objectionable.
          </li>
          <li>
            Promote sexually explicit or pornographic material, violence, or
            discrimination based on race, sex, religion, nationality,
            disability, sexual orientation, or age.
          </li>
          <li>
            Infringe any patent, trademark, trade secret, copyright, or other
            intellectual property or other rights of any other person.
          </li>
          <li>
            Violate the legal rights (including the rights of publicity and
            privacy) of others or contain any material that could give rise to
            any civil or criminal liability under applicable laws or regulations
            or that otherwise may be in conflict with these Terms of Use and our
            EULA.
          </li>
          <li>Be likely to deceive any person.</li>
          <li>
            Promote any illegal activity, or advocate, promote, or assist any
            unlawful act.
          </li>
          <li>
            Cause annoyance, inconvenience, or needless anxiety or be likely to
            upset, embarrass, alarm, or annoy any other person.
          </li>
          <li>
            Impersonate any person or misrepresent your identity or affiliation
            with any person or organization.
          </li>
          <li>
            Involve sales activities including contests, sweepstakes, and other
            sales promotions, barter, or advertising.
          </li>
          <li>
            Give the impression that they emanate from or are endorsed by us or
            any other person or entity, if this is not the case.
          </li>
        </ul>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Reliance on Information Posted</u>
          </strong>
        </p>
        <p>
          The information presented on or through the Application is made
          available solely for the Company’s intended purposes. We do not
          warrant the accuracy, completeness, or usefulness of this information.
          Any reliance you place on such information is strictly at your own
          risk. We disclaim all liability and responsibility arising from any
          reliance placed on such materials by you, or by anyone who may be
          informed of any of its contents.
        </p>
        <p>
          This Application may include content provided by third parties,
          including materials provided by other users, bloggers, and third-party
          licensors, syndicators, aggregators, and/or reporting services. All
          statements and/or opinions expressed in these materials, and all
          articles and responses to questions and other content, other than the
          content provided by the Company, are solely the opinions and the
          responsibility of the person or entity providing those materials.
          These materials do not necessarily reflect the opinion of the Company.
          We are not responsible, or liable to you or any third party, for the
          content or accuracy of any materials provided by any third parties.
        </p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Changes to the </u>
          </strong>
          <strong>
            <u>Application</u>
          </strong>
        </p>
        <p>
          We may update the content on this Application from time to time, but
          its content is not necessarily complete or up-to-date. Any of the
          material on the Application may be out of date at any given time, and
          we are under no obligation to update such material.
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Links from the </u>
          </strong>
          <strong>
            <u>Application</u>
          </strong>
        </p>
        <p>
          If the Application contains links to other sites and resources
          provided by third parties, these links are provided for your
          convenience only. This includes links contained in advertisements,
          including banner advertisements and sponsored links. We have no
          control over the content of those sites or resources and accept no
          responsibility for them or for any loss or damage that may arise from
          your use of them. If you decide to access any of the third-party
          websites linked to this Application, you do so entirely at your own
          risk and subject to the terms and conditions of use for such websites.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Geographic Restrictions</u>
          </strong>
        </p>
        <p>
          The owner of the Application is based in the State of Tennessee in the
          United States. We provide this Application for use only by Authorized
          Users located in the United States. We make no claims that the
          Application or any of its content is accessible or appropriate outside
          of the United States. Access to the Application may not be legal by
          certain persons or in certain countries. If you access the Application
          from outside the United States, you do so on your own initiative and
          are responsible for compliance with local laws.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Disclaimer of Warranties</u>
          </strong>
        </p>
        <p>
          You understand that we cannot and do not guarantee or warrant that
          files available for downloading from the internet or the Application
          will be free of viruses or other destructive code. You are responsible
          for implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY
          LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A
          DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
          TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
          EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE
          TO YOUR USE OF THE APPLICATION OR ANY SERVICES OR ITEMS OBTAINED
          THROUGH THE APPLICATION OR YOUR DOWNLOADING OF ANY MATERIAL POSTED ON
          IT, OR ON ANY APPLICATION LINKED TO IT.
        </p>
        <p>
          YOUR USE OF THE APPLICATION, ITS CONTENT, AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE APPLICATION IS AT YOUR OWN RISK. THE APPLICATION,
          ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
          APPLICATION ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
          WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER
          THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY
          WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE APPLICATION.
          WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
          ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
          APPLICATION, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE APPLICATION WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
          UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE
          SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS, OR THAT THE APPLICATION OR ANY SERVICES OR ITEMS OBTAINED
          THROUGH THE APPLICATION WILL OTHERWISE MEET YOUR NEEDS OR
          EXPECTATIONS.
        </p>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS
          ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Limitation on Liability</u>
          </strong>
        </p>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY,
          ITS AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
          AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
          UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE,
          OR INABILITY TO USE, THE APPLICATION, ANY WEBSITES LINKED TO IT, ANY
          CONTENT ON THE APPLICATION OR SUCH OTHER WEBSITES, INCLUDING ANY
          DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
          DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
          SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS
          OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
          LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
          BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
        </p>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
          LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>&nbsp;</u>
          </strong>
        </p>
        <p>
          <strong>
            <u>Indemnification</u>
          </strong>
        </p>
        <p>
          You agree to defend, indemnify, and hold harmless the Company, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys' fees) arising out
          of or relating to your violation of these Terms of Use or your use of
          the Application, including, but not limited to, your User
          Contributions, any use of the Application's content, services, and
          products other than as expressly authorized in these Terms of Use, or
          your use of any information obtained from the Application.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Governing Law and Jurisdiction</u>
          </strong>
        </p>
        <p>
          All matters relating to the Application and these Terms of Use, and
          any dispute or claim arising therefrom or related thereto (in each
          case, including non-contractual disputes or claims), shall be governed
          by and construed in accordance with the internal laws of the State of
          Tennessee without giving effect to any choice or conflict of law
          provision or rule (whether of the State of Tennessee or any other
          jurisdiction).
        </p>
        <p>
          Any legal suit, action, or proceeding arising out of, or related to,
          these Terms of Use or the Application shall be instituted exclusively
          in the federal courts of the United States or the courts of the State
          of Tennessee, in each case located in the City of Nashville and County
          of Davidson, &nbsp;although we retain the right to bring any suit,
          action, or proceeding against you for breach of these Terms of Use in
          your country of residence or any other relevant country. You waive any
          and all objections to the exercise of jurisdiction over you by such
          courts and to venue in such courts.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Arbitration</u>
          </strong>
        </p>
        <p>
          At Company's sole discretion, it may require you to submit any
          disputes arising from these Terms of Use or use of the Application,
          including disputes arising from or concerning their interpretation,
          violation, invalidity, non-performance, or termination, to final and
          binding arbitration under the Rules of Arbitration of the American
          Arbitration Association applying Tennessee law.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Limitation on Time to File Claims</u>
          </strong>
        </p>
        <p>
          ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
          TO THESE TERMS OF USE OR THE APPLICATION MUST BE COMMENCED WITHIN ONE
          (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF
          ACTION OR CLAIM IS PERMANENTLY BARRED.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Waiver and Severability</u>
          </strong>
        </p>
        <p>
          No waiver by the Company of any term or condition set out in these
          Terms of Use shall be deemed a further or continuing waiver of such
          term or condition or a waiver of any other term or condition, and any
          failure of the Company to assert a right or provision under these
          Terms of Use shall not constitute a waiver of such right or provision.
        </p>
        <p>
          If any provision of these Terms of Use is held by a court or other
          tribunal of competent jurisdiction to be invalid, illegal, or
          unenforceable for any reason, such provision shall be eliminated or
          limited to the minimum extent such that the remaining provisions of
          the Terms of Use will continue in full force and effect.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Entire Agreement</u>
          </strong>
        </p>
        <p>
          The Terms of Use, EULA, and Privacy Policy constitute the sole and
          entire agreement between you and Honest Medical Group LLC regarding
          the Application and supersede all prior and contemporaneous
          understandings, agreements, representations, and warranties, both
          written and oral, regarding the Application.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <u>Your Comments and Concerns</u>
          </strong>
        </p>
        <p>
          This Application and Website are operated by Honest Medical Group LLC,
          located at 210 Athens Way, Suite 100 Nashville, TN 37228.
        </p>
        <p>
          All feedback, comments, requests for technical support, and other
          communications relating to the Application should be directed to:
          HonestCareHub@honestmedicalgroup.com.
        </p>
      </>
    </Container>
  );
};

export default TermsOfUsage;
