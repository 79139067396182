import React, { useState, useEffect } from "react";
import {
  Container,
  Table,
  Button,
  Form,
  Row,
  Col,
  Modal,
  Breadcrumb,
  Spinner,
  Pagination,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { MdEdit } from "react-icons/md";
import { FaUnlock, FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import { Navigate, Link } from "react-router-dom";
import { fetchData, patchData } from "../../api/apiService";
import Loader from "../../ui/Layout/Loader";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "react-bootstrap";
import { usePermissions } from "carehub_auth_permissions";

const initialFormState = {
  name: "",
  email: "",
  partnerId: 0,
  practiceIds: [],
  roleIds: [],
  npi: "",
  tin: "",
};

const RequestDetails = ({ roles }) => {
  const [showModal, setShowModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(initialFormState);
  const [partners, setPartners] = useState([]);
  const [practiceOptions, setPracticeOptions] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const itemsPerPage = 10;
  const [error, setError] = useState("");
  const { permissions, permissionsLoaded } = usePermissions();
  const onSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      await handleSubmit(e);
    } else {
      setError("Please fill out all required fields correctly.");
    }
  };

  const fetchUsers = async (
    page,
    query = "",
    sortBy = "",
    sortOrder = "asc"
  ) => {
    setLoading(true);
    try {
      let url = `/users?page=${page}&limit=${itemsPerPage}&searchFields=name,email&searchValues=${query},${query}`;
      if (sortBy) {
        url += `&sortBy=${sortBy}&sortOrder=${sortOrder}`;
      }
      const response = await fetchData(url);
      setUsers(response.users);
      setTotalPages(Math.ceil(response.usersCount / itemsPerPage));
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(currentPage, searchQuery, sortBy, sortOrder);

    const fetchPartners = async () => {
      try {
        const response = await fetchData("/partners/partnersWithPractices");
        setPartners(response);
      } catch (error) {
        console.error("Error fetching partners:", error);
      }
    };

    fetchPartners();
  }, [currentPage, searchQuery, sortBy, sortOrder]);

  const handleToggleStatus = async (userId, isActive) => {
    try {
      const user = users.find((user) => user.id === userId); // Find user to get the name
      const url = `/users/${userId}/${isActive ? "deactivate" : "activate"}`;
      await patchData(url, {});
      fetchUsers(currentPage, searchQuery, sortBy, sortOrder);
      setTimeout(() => {
        toast.success(
          `User : ${user.name} ${
            isActive ? "deactivated" : "activated"
          } successfully` 
        );
      }, 500); 
    } catch (error) {
      console.error(`Error toggling user status:`, error);
      toast.error(error.message);
    }
  };

  const handleUnlockUser = async (userId) => {
    try {
      const user = users.find((user) => user.id === userId); 
      const url = `/users/${userId}/unlock`;
      await patchData(url, { isActive: true });
      fetchUsers(currentPage, searchQuery, sortBy, sortOrder);
      setTimeout(() => {
        toast.success(`User : ${user.name} unlocked successfully`); 
      }, 500); 
    } catch (error) {
      console.error(`Error unlocking user:`, error);
      toast.error("Failed to unlock user. Please try again later.");
    }
  };

  const handleEditClick = (user) => {
    setSelectedUserId(user.id);
    setFormData({
      ...user,
      partnerId: user.partner ? user.partner.id : 0,
      practiceIds: user.partner ? user.partner.practices.map((p) => p.id) : [],
      roleIds: user.roles ? user.roles.map((role) => role.id) : [], // Updated to set roleIds array
    });

    // Setting practiceOptions based on selected partner
    const selectedPartner = user.partner
      ? partners.find((partner) => partner.id === user.partner.id)
      : null;

    setPracticeOptions(
      selectedPartner
        ? selectedPartner.practices.map((practice) => ({
            value: practice.id,
            label: practice.name,
          }))
        : []
    );

    setShowModal(true);
  };

  const isNameValid = formData.name.trim() !== "";
  const isEmailValid = formData.email.includes("@");
  const isRoleValid = formData.roleIds.length >= 0;

  const isFormValid = isNameValid && isEmailValid && isRoleValid;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "partnerId") {
      const selectedPartner = partners.find(
        (partner) => partner.id === parseInt(value)
      );
      setPracticeOptions(
        selectedPartner
          ? selectedPartner.practices.map((practice) => ({
              value: practice.id,
              label: practice.name,
            }))
          : []
      );
    }
  };

  const handlePracticeChange = (selectedOptions) => {
    setFormData((prev) => ({
      ...prev,
      practiceIds: selectedOptions.map((option) => option.value),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedFormData = {
      name: formData.name,
      email: formData.email,
      partnerId: formData.partnerId ? parseInt(formData.partnerId) : null,
      practiceIds: formData.practiceIds || [],
      roleIds: formData.roleIds || [],
      npi: formData.npi ? formData.npi.trim() : null,
      tin: formData.tin ? formData.tin.trim() : null,
    };

    try {
      await patchData(`/users/${selectedUserId}`, updatedFormData);
      setShowModal(false);
      fetchUsers(currentPage, searchQuery, sortBy, sortOrder);
      setTimeout(() => {
        toast.success(`User : ${formData.name} updated successfully`);
      }, 500); 
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(1); // Reset to first page on new search
    fetchUsers(1, searchQuery, sortBy, sortOrder);
  };

  const handleSort = (field) => {
    const order = sortBy === field && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(field);
    setSortOrder(order);
  };

  const renderSortIcon = (field) => {
    if (sortBy === field) {
      return sortOrder === "asc" ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort />;
  };

  if (!permissionsLoaded || loading) {
    return <Loader />;
  }

  return (
    <div>
      {permissions?.includes("user_management_request_details") ? (
        <Container className="border border-light rounded px-5 mt-2">
          <ToastContainer />
          {error && <Alert variant="danger">{error}</Alert>}

          <Breadcrumb>
            <Breadcrumb.Item>
              <Link className="text-dark link" to="/administration">
                Administration
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link className="text-dark link" to="/user_management">
                User Management
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>View Users</Breadcrumb.Item>
          </Breadcrumb>
          <h3>Users List</h3>
          <Form
            onSubmit={handleSearchSubmit}
            className="mb-3 d-flex justify-content-end"
          >
            <InputGroup className="w-25">
              <FormControl
                placeholder="Search users"
                value={searchQuery}
                onChange={handleSearchChange}
                size="sm"
              />
              <Button variant="outline-secondary" type="submit" size="sm">
                Search
              </Button>
            </InputGroup>
          </Form>
          {loading ? (
            <Loader />
          ) : (
            <Container className="container mt-4">
              <Table striped bordered hover className="rounded mt-1">
                <thead>
                  <tr>
                    <th></th>
                    <th
                      onClick={() => handleSort("name")}
                      style={{ cursor: "pointer" }}
                    >
                      Name
                      {renderSortIcon("name")}
                    </th>
                    <th
                      onClick={() => handleSort("email")}
                      style={{ cursor: "pointer" }}
                    >
                      Email
                      {renderSortIcon("email")}
                    </th>
                    <th>Role</th>
                    <th>Action</th>
                    <th>Activate / Deactivate</th>
                    <th>Unlock</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((user, index) => (
                    <tr key={user.id}>
                      <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>
                        {user.roles && user.roles.length > 0
                          ? user.roles.map((role) => role.name).join(", ")
                          : "N/A"}
                      </td>
                      <td>
                        <Button
                          onClick={() => handleEditClick(user)}
                          className="btn btn-secondary me-2"
                        >
                          <MdEdit />
                        </Button>
                      </td>
                      <td>
                        <Form.Check
                          type="switch"
                          id={`user-status-switch-${user.id}`}
                          className="d-flex justify-content-center align-items-center"
                          onChange={() =>
                            handleToggleStatus(user.id, user.isActive)
                          }
                          checked={user.isActive}
                          style={{
                            minWidth: "60px",
                            minHeight: "30px",
                          }}
                        />
                      </td>
                      <td>
                        {!user.isActive && (
                          <Button
                            onClick={() => handleUnlockUser(user.id)}
                            className="btn btn-secondary"
                          >
                            <FaUnlock />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Container className="d-flex justify-content-between align-items-center mt-4">
                <div>
                  Page {currentPage} of {totalPages}
                </div>
                <Pagination className="justify-content-end">
                  <Pagination.First
                    onClick={() => handlePageChange(1)}
                    disabled={currentPage === 1}
                  />
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {[...Array(totalPages)].map((_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                  <Pagination.Last
                    onClick={() => handlePageChange(totalPages)}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </Container>
            </Container>
          )}
          <UserEditModal
            showModal={showModal}
            handleClose={() => setShowModal(false)}
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            partners={partners}
            practiceOptions={practiceOptions}
            roles={roles}
            handlePracticeChange={handlePracticeChange}
            loading={loading}
          />
        </Container>
      ) : (
        <Navigate to="/401" replace />
      )}
      </div>
  );
};

const UserEditModal = ({
  showModal,
  handleClose,
  formData,
  handleChange,
  handleSubmit,
  partners,
  practiceOptions,
  roles,
  handlePracticeChange,
  loading,
}) => {
  const isNameValid = formData.name.trim() !== "";
  const isEmailValid = formData.email.includes("@");
  const isRoleValid = formData.roleIds.length >= 0;
  const isFormValid = isNameValid && isEmailValid && isRoleValid;

  return (
    <Modal show={showModal} onHide={handleClose} className="modal-xl">
      <Modal.Header closeButton>
        <Modal.Title>Edit User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group controlId="name">
                <Form.Label>
                  <strong>
                    Name <span style={{ color: "red" }}>*</span>
                  </strong>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  isInvalid={!isNameValid}
                />
                <Form.Control.Feedback type="invalid">
                  Name is required.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="email">
                <Form.Label>
                  <strong>
                    Email <span style={{ color: "red" }}>*</span>
                  </strong>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  disabled
                  isInvalid={!isEmailValid}
                />
                <Form.Control.Feedback type="invalid">
                  Enter a valid email.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="partner">
                <Form.Label>Partner</Form.Label>
                <Form.Select
                  name="partnerId"
                  value={formData.partnerId}
                  onChange={handleChange}
                >
                  <option value="">Select Partner</option>
                  {partners.map((partner) => (
                    <option key={partner.id} value={partner.id}>
                      {partner.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="practice">
                <Form.Label>Practice</Form.Label>
                <Select
                  isMulti
                  name="practiceIds"
                  options={practiceOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={handlePracticeChange}
                  value={practiceOptions.filter((option) =>
                    formData.practiceIds.includes(option.value)
                  )}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="role">
                <Form.Label>
                  <strong>Role</strong>
                </Form.Label>
                <Select
                  isMulti
                  name="roleIds"
                  options={roles.map((role) => ({
                    value: role.id,
                    label: role.name,
                  }))}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    handleChange({
                      target: {
                        name: "roleIds",
                        value: selectedOptions.map((option) => option.value),
                      },
                    })
                  }
                  value={roles
                    .filter((role) => formData.roleIds?.includes(role.id))
                    .map((role) => ({
                      value: role.id,
                      label: role.name,
                    }))}
                  isInvalid={!isRoleValid}
                />
                <Form.Control.Feedback type="invalid">
                  Select at least one role.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Form.Group controlId="npi">
                <Form.Label>NPI</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter NPI"
                  name="npi"
                  value={formData.npi}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="tin">
                <Form.Label>TIN</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter TIN"
                  name="tin"
                  value={formData.tin}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={!isFormValid || loading}
            >
              {loading ? <Spinner animation="border" size="sm" /> : "Submit"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RequestDetails;
