import React, { useState, useEffect } from "react";
import Select from "react-select";
import { FaEye } from "react-icons/fa";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Modal,
  Button,
  Spinner,
  Alert,
} from "react-bootstrap";
import { Navigate, Link } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { postData, fetchData } from "../../api/apiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ALLOWED_EMAIL_DOMAINS } from "../../config/app.constant";
import { usePermissions } from "carehub_auth_permissions";
import Loader from "../../ui/Layout/Loader";

const UserManagement = ({ roles }) => {
  const initialFormState = {
    name: "",
    email: "",
    partnerId: 0,
    practiceIds: [],
    roleIds: [],
    npi: "",
    tin: "",
  };

  const [showModalOnBoarding, setShowModalOnBoarding] = useState(false);
  const handleCloseOnBoarding = () => setShowModalOnBoarding(false);
  const handleShowOnBoarding = () => {
    setFormData(initialFormState); // Reset form data
    setIsEmailValid(emailDomainValid(initialFormState.email));

    setShowModalOnBoarding(true);
  };

  const [formData, setFormData] = useState(initialFormState);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [partners, setPartners] = useState([]);
  const [practiceOptions, setPracticeOptions] = useState([]);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const { permissions, permissionsLoaded } = usePermissions();

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await fetchData("/partners/partnersWithPractices");
        setPartners(response);
      } catch (error) {
        console.error("Error fetching partners:", error);
      }
    };
    fetchPartners();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Email validation check
    if (name === "email") {
      setIsEmailValid(emailDomainValid(value)); // Set validity based on domain
    }

    if (name === "partnerId") {
      const selectedPartner = partners.find(
        (partner) => partner.id === parseInt(value)
      );
      setPracticeOptions(
        selectedPartner
          ? selectedPartner.practices.map((practice) => ({
              value: practice.id,
              label: practice.name,
            }))
          : []
      );
    }
  };

  const emailDomainValid = (email) => {
    return ALLOWED_EMAIL_DOMAINS.some((domain) => email.endsWith(domain));
  };

  const handlePracticeChange = (selectedOptions) => {
    const practiceIds = selectedOptions.map((option) => option.value);
    setFormData({
      ...formData,
      practiceIds,
    });
  };

  const handleRoleChange = (selectedOptions) => {
    const roleIds = selectedOptions.map((option) => option.value);
    setFormData({
      ...formData,
      roleIds,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const updatedFormData = {
      ...formData,
      partnerId: formData.partnerId ? parseInt(formData.partnerId) : null,
      npi: formData.npi.trim() === "" ? null : formData.npi,
      tin: formData.tin.trim() === "" ? null : formData.tin,
    };

    try {
      await postData("/users", updatedFormData);
      handleCloseOnBoarding();
      setTimeout(() => {
        toast.success("User Added Successfully");
      }, 500); 
    } catch (error) {
      console.error("Error posting data:", error);

      // Directly check for specific error formats
      if (error.statusCode === 400 && Array.isArray(error.message)) {
        // Display validation errors as a list
        const errorMessages = error.message.join("\n");
        setError(errorMessages);
      } else if (error.statusCode === 409) {
        // Display specific conflict error, e.g., email already exists
        setError(error.message);
      } else {
        // Fallback for unexpected error formats
        setError("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Validation checks
  // const isEmailValid = formData.email.includes("@");
  const isNameValid = formData.name.trim() !== "";
  const isRoleValid = formData.roleIds.length >= 0;

  // Enable Submit button if Name, Email, and Role are filled correctly
  const isFormValid = isNameValid && isEmailValid && isRoleValid;

  if (!permissionsLoaded || loading) {
    return <Loader />;
  }

  return (
    <div>
      {permissions?.includes("administration_user_management") ? (
        <Container className="border border-light rounded px-5 mt-2">
        <ToastContainer />
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link className="text-dark link" to="/administration">
              Administration
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>User Management</Breadcrumb.Item>
        </Breadcrumb>
        <h3>User Management</h3>
        <Row className="justify-content-center mt-4">
          {permissions?.includes("user_management_request_details") && (
            <Col md={4}>
              <Card>
                <div className="card-body insights-card">
                  <div className="card-text text-center rounded pt-3">
                    <h5 className="fw-bold mt-2">View Users</h5>
                  </div>
                  <div>
                    <p className="fs-6 p-3 text-center">
                      Manage and view the details of all users in the system.
                    </p>
                  </div>
                </div>
                <div className="card-footer primary-bg text-center text-white">
                  <Link to="/request_details" className="nav-link text-white">
                    <FaEye /> View
                  </Link>
                </div>
              </Card>
            </Col>
          )}
          {permissions?.includes("user_management_onboarding") && (
            <Col md={4}>
              <Card>
                <div className="card-body insights-card">
                  <div className="card-text text-center rounded pt-3">
                    <h5 className="fw-bold mt-2">Add User</h5>
                  </div>
                  <div>
                    <p className="fs-6 p-3 text-center">
                      Add a new user to the system with the required details.
                    </p>
                  </div>
                </div>
                <div className="card-footer primary-bg text-center text-white">
                  <Link
                    onClick={handleShowOnBoarding}
                    className="nav-link text-white"
                  >
                    <FaEye /> View
                  </Link>
                </div>
              </Card>
            </Col>
          )}
        </Row>
        <Modal
          className="modal-xl"
          show={showModalOnBoarding}
          onHide={handleCloseOnBoarding}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <Form.Group controlId="configName">
                    <Form.Label>
                      <strong>
                        Name <span style={{ color: "red" }}>*</span>
                      </strong>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      isInvalid={!isNameValid}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="configValue">
                    <Form.Label>
                      <strong>
                        Email <span style={{ color: "red" }}>*</span>
                      </strong>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      isInvalid={!isEmailValid} // Set invalid if not valid
                    />
                    <Form.Control.Feedback type="invalid">
                      Please enter a valid email with{" "}
                      {ALLOWED_EMAIL_DOMAINS.join(" or ")}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="partner">
                    <Form.Label>Partner</Form.Label>
                    <Form.Select
                      name="partnerId"
                      value={formData.partnerId}
                      onChange={handleChange}
                    >
                      <option value="">Select Partner</option>
                      {partners.map((partner) => (
                        <option key={partner.id} value={partner.id}>
                          {partner.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="practice">
                    <Form.Label>Practice</Form.Label>
                    <Select
                      isMulti
                      name="practiceIds"
                      options={practiceOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handlePracticeChange}
                      value={practiceOptions.filter((option) =>
                        formData.practiceIds.includes(option.value)
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="role">
                    <Form.Label>
                      <strong>Role</strong>
                    </Form.Label>
                    <Select
                      isMulti
                      name="roleIds"
                      options={roles.map((role) => ({
                        value: role.id,
                        label: role.name,
                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleRoleChange}
                      value={roles
                        .filter((role) => formData.roleIds.includes(role.id))
                        .map((role) => ({
                          value: role.id,
                          label: role.name,
                        }))}
                      isInvalid={!isRoleValid}
                    />
                    {!isRoleValid && (
                      <div className="invalid-feedback d-block">
                        Please select at least one role.
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Form.Group controlId="npi">
                    <Form.Label>NPI</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter NPI"
                      name="npi"
                      value={formData.npi}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="tin">
                    <Form.Label>TIN</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter TIN"
                      name="tin"
                      value={formData.tin}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-3 justify-content-end">
                <Col xs="auto">
                  <Button variant="secondary" onClick={handleCloseOnBoarding}>
                    Close
                  </Button>
                </Col>
                <Col xs="auto">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={!isFormValid || loading}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Col>
                <Col xs={12}>
                  {error && (
                    <Alert variant="danger" className="mt-3">
                      {error}
                    </Alert>
                  )}
                </Col>
              </Row>
            </Form>
          </Modal.Body>
        </Modal>
        </Container>
      ) : (
        <Navigate to="/401" replace />
      )}
      </div>
  );
};

export default UserManagement;
