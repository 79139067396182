export const API_URL = process.env.REACT_APP_BASE_URL;
export const BRANDING_EMAIL_DOMAIN = "https://honesthealth.com";
export const ALLOWED_EMAIL_DOMAINS = ["@honesthealth.com"];
export const LOG_MESSAGE = {
  USER_LOGGED_IN: "User LoggedIn",
  LOAD_INSIGHTS: "Loading Insights Home Page",
  FETCH_PERMISSION: "Fetched User Permission",
  FETCH_APP_CONFIG: "Fetched App Configuration",
  FETCH_CHART_URL: "Fetching Chart Url",
  LOAD_HOME: "Loading Home",
  USER_LOGGED_OUT: "User Logged Out",
};
