import axios from "axios";
import { API_URL } from "../config/app.constant";

const DEFAULT_TIMEOUT = 30000;
let cachedTimeout = null;

const checkToken = (redirect = true) => {
  const token = localStorage.getItem("accessToken");
  if (!token || token === "undefined") {
    if (redirect && window.location.pathname !== "/") {
      window.location.href = "/";
    }
    return null;
  }
  return token;
};

const fetchAndSetTimeout = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/application-configuration?key=api_request_timeout`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      timeout: DEFAULT_TIMEOUT,
    });

    const config = response.data.application_configurations.find(
      (item) => item.name === "api_request_timeout"
    );

    const timeoutValue = config ? parseInt(config.value, 10) : DEFAULT_TIMEOUT;
    localStorage.setItem("api_request_timeout", timeoutValue);
    cachedTimeout = timeoutValue;
  } catch (error) {
    cachedTimeout = DEFAULT_TIMEOUT;
    localStorage.setItem("api_request_timeout", DEFAULT_TIMEOUT);
  }
};

const getTimeout = async (token) => {
  if (!cachedTimeout) {
    let timeoutValue = parseInt(localStorage.getItem("api_request_timeout"), 10);
    if (isNaN(timeoutValue)) {
      await fetchAndSetTimeout(token);
      timeoutValue = cachedTimeout;
    } else {
      cachedTimeout = timeoutValue;
    }
  }
  return cachedTimeout;
};

const apiRequest = async (method, url, data = {}, isMultipart = false) => {
  const token = checkToken();
  if (!token) return;

  await getTimeout(token);

  const headers = {
    "Content-Type": isMultipart ? "multipart/form-data" : "application/json",
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios({
      method,
      url: `${API_URL}${url}`,
      data: method === "get" ? null : data,
      headers,
      timeout: cachedTimeout,
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("accessToken");
      if (window.location.pathname !== "/") {
        window.location.href = "/";
      }
    } else {
      throw error.response ? error.response.data : error.message;
    }
  }
};

export const fetchData = (url) => apiRequest("get", url).then((response) => response.data);
export const patchData = (url, body = {}) => apiRequest("patch", url, body);
export const postData = (url, data) => apiRequest("post", url, data).then((response) => response.data);
export const deleteData = (url) => apiRequest("delete", url).then((response) => response.data);
export const postMultipartData = (url, formData) => apiRequest("post", url, formData, true).then((response) => response.data);
